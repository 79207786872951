@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  --bs-primary: #dd6352; /* Override primary color */
  --bs-secondary: #3d6b7d;
  --dark: #2d2926;
  --gray: #eaebeb;
  --bs-font-sans-serif: "Effra", sans-serif; /* Override default font */
  --bs-primary-border-subtle: #dd6352;

  /* Buttons */
  --bs-btn-hover-color: #d99086;
  --bs-btn-hover-bg: #dd62528b;
  --bs-btn-hover-border-color: #dd6352;
  --bs-primary-rgb: 221, 99, 82;
  --bs-btn-active-bg: #dd6352;
  --bs-btn-active-border-color: #dd6352;
  --bs-btn-active-color: #fff;
  --bs-btn-bg: #dd6352;
  --bs-btn-color: #fff;
  --bs-btn-border-color: #dd6352;
  --bs-link-color: #dd6352;
  --bs-link-hover-color: #dd6352;
}

/* Fonts */

@font-face {
  font-family: "Effra";
  src: url("../public/fonts/Effra_Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Effra";
  src: url("../public/fonts/Effra_Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Effra";
  src: url("../public/fonts/Effra_Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Effra";
  src: url("../public/fonts/Effra_Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Font sizes */

/* Large screens (desktops) */
h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
  margin: 0;
}

p,
li {
  font-size: 1rem;
  font-weight: 300;
}

.btn,
button {
  font-size: 1rem;
  line-height: 1;
}

/* Global */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

span {
  color: var(--bs-primary);
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.primary-font {
  font-family: "Effra", sans-serif !important;
}

.btn {
  width: fit-content;
  background-color: var(--bs-primary);
  color: white;
  padding: 0.8rem 1.6rem;
  font-family: "Sora", sans-serif;
  text-transform: capitalize;
  font-weight: 700;
  font-style: italic;
  border-radius: 5rem;
  text-wrap: nowrap;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-color: #fff;
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: white;
}

.inactive {
  background-color: var(--gray2);
  color: var(--gray3);
  border: none;
}

.btn:focus,
.btn:active,
.btn:visited,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:visited {
  background-color: var(--bs-primary) !important;
  color: white;
}

.btn-primary.active {
  background-color: var(--bs-primary);
  border: none;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: var(--bs-primary);
  color: white;
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-primary);
}

/* Custom classes */

/* Navbar */

.logo {
  width: 12rem;
  height: auto;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 10rem;
  }
}

.nav-link {
  color: var(--dark);
  font-family: "Effra", sans-serif;
  font-style: italic;
  font-weight: 500;
  box-sizing: border-box;
  height: 2rem;
}

.hamburgerIcon {
  color: white !important;
  font-size: 1.5rem !important;
}

.navbar-toggler {
  border-radius: 100%;
  border: none;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

.side-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  background-color: var(--dark);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1050;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  border-left: 4px solid var(--bs-primary);
  border-radius: 5px 0 0 5px;
}

.bi-x-lg {
  color: white !important;
  font-size: 1.5rem !important;
}

.close-button {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.side-modal .navbar-nav .nav-link {
  color: white;
  margin: 1rem 0;
}

.bi-arrow-right {
  color: var(--bs-primary);
  font-size: 1.5rem;
}

.bg-green {
  background-color: #24d366 !important;
  border-color: #24d366 !important;
}

.bg-green:hover,
.bg-green:focus,
.bg-green:active {
  background-color: #128c7e !important;
  border-color: #128c7e !important;
}

.whatsapp-button {
  background-color: #24d366;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.whatsapp-button .bi {
  color: white;
  font-size: 1.5rem;
}

.whatsapp-button:hover {
  background-color: rgba(36, 211, 102, 0.7);
}

/* Hero */
.top-bg {
  position: relative;
  width: 100%;
  height: fit-content;
  background-image: url("./images/herobg.webp");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Adjust the opacity here */
  z-index: 1;
}

.top-bg > *:not(.overlay) {
  position: relative;
  z-index: 2;
}

header {
  height: 80vh;
  color: white;
}

h1 {
  font-size: 5rem;
  font-family: "Effra", sans-serif;
  font-weight: 700;
  font-style: italic;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  margin: 0;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 4rem;
  }
}

h3 {
  font-size: 2rem;
  font-family: "Effra", sans-serif;
  font-weight: 300;
  font-style: italic;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  margin: 0;
  line-height: 1;
}

/* About */

.about-bg {
  position: relative;
  width: 100%;
  background-color: var(--dark);
}

.about {
  height: 40rem;
}

.about-content {
  max-width: 40rem;
  margin: 0 auto;
}

.about h1,
.about h4,
.about p,
.about button {
  z-index: 1;
}

h4 {
  color: var(--bs-secondary);
  font-weight: 300;
}

.image-container {
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.about .image-container {
  background-image: url("./images/welcomebg.webp");
}

.about-bg .watermark {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  max-width: 40rem;
}

.about .divider {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

@media screen and (max-width: 768px) {
  .about {
    height: fit-content;
    padding: 1rem 0;
  }

  .about .image-container {
    display: none;
  }

  .about-bg .watermark {
    width: 100%;
    top: 45%;
  }
}

/* Amenities */

.amenities-bg {
  position: relative;
  width: 100%;
  background-color: var(--dark);
}

.amenities {
  height: 40rem;
  background-color: var(--dark);
  color: white;
}

.amenities-content {
  max-width: 40rem;
  margin: 0 auto;
}

.amenities h1,
.amenities h4,
.amenities p,
.amenities-list,
.amenities button {
  z-index: 1;
}

.amenities .image-container {
  background-image: url("./images/aboutbg.webp");
}

.amenities .divider {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
}

.amenities-bg .watermark {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  max-width: 40rem;
}

.amenities-list {
  column-gap: 1rem; /* Adjust gap as needed */
}

.amenities-list li {
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.icon {
  height: 1.8rem;
  width: auto;
}

@media screen and (max-width: 768px) {
  .amenities {
    height: fit-content;
    padding-bottom: 1rem;
  }

  .amenities .image-container {
    display: none;
  }

  .amenities-bg .watermark {
    width: 100%;
    top: 45%;
  }

  .amenities-list li {
    font-size: 1rem;
    text-wrap: nowrap;
  }
}

/* Instagram */
.instagram {
  background-color: white;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
}

.ig-btn {
  background-color: var(--gray);
  color: black;
  border-radius: 5px;
  font-size: 0.8rem;
  text-decoration: none;
}

.ig-img {
  width: 100%;
  max-width: 20rem;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .d-md-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .instagram .slick-slide {
    text-align: center;
    padding: 10px;
  }
}

/* Academy */

.academy-bg {
  position: relative;
  width: 100%;
  height: fit-content;
  background-image: url("./images/academybg.webp");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

section .container {
  max-width: 50rem;
  border-radius: 5px;
}

.img-101 {
  width: 20rem;
  height: auto;
  position: absolute;
  top: 25px;
  left: -10px;
  rotate: -30deg;
  opacity: 0.8;
}

/* Icons */

.icons {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
}

.description {
  max-width: 60rem;
}

.icon-name {
  font-size: 2rem;
  font-weight: bold;
}

.icon-position {
  font-size: 1rem;
  font-style: italic;
  color: var(--bs-secondary);
}

.icon-img {
  max-width: 20rem;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .icon-img {
    width: 100%;
    height: auto;
  }
}

/* Open Play */

.openplay-bg {
  width: 100%;
  height: fit-content;
  background-image: url("./images/openplaybg.webp");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

/* Tutorial */

.tutorial-bg {
  position: relative;
  width: 100%;
  height: fit-content;
  background-image: url("./images/tutorialbg.webp");
  background-color: white;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.video-container {
  width: 100%;
  height: 25rem;
  background-color: var(--gray);
}

/* Calendar */

#calendar {
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
}

.calendar-img {
  max-width: 20rem;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .icon-img {
    width: auto;
    height: 100%;
  }
}

/* Footer */

.footer-bg {
  background-color: var(--dark);
  box-sizing: border-box;
}

.contact-icon {
  padding: 1rem;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  background-color: var(--bs-secondary);
  font-size: 1.2rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.map {
  background-color: var(--gray);
  width: 90%;
  height: 30rem;
}

@media screen and (max-width: 768px) {
  .map {
    width: 100%;
    height: 30rem;
  }
}

/* Coming Soon */

.comingSoon {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(110.02deg, rgba(45, 41, 38, 0.6) 0%, #2d2926 100%),
    url('./images/ComingSoonBG.webp');
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  touch-action: none; /* This can help prevent unwanted touch interactions */
  overflow: hidden;
  -webkit-overflow-scrolling: touch; /* This ensures smooth scrolling on iOS */
}

.maxWidth {
  max-width: 120rem;
  margin: 0 auto;
}

.comingSoon main {
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  box-sizing: border-box;
}

.monogram {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
  max-width: 80rem;
}

.comingSoon .content {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
}

.comingSoon .title {
  font-size: 6rem;
  color: white;
  text-align: center;
  font-family: 'Effra', sans-serif;
  font-weight: bold;
  font-style: italic;
  margin: 1rem 0;
}

.comingSoon .logo {
  width: 15rem;
  margin: 0;
  box-sizing: border-box;
}

.comingSoon .instagramContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  gap: 0.5rem;
  text-decoration: none;
  margin-top: 4rem;
}

.comingSoon .instagramLogo {
  width: auto;
  height: 10rem;
}

.comingSoon .instagramContainer h3 {
  color: white;
  font-size: 3rem;
  font-family: 'Effra', sans-serif;
  font-weight: bold;
  margin: 0;
}
.comingSoon .instagramContainer p {
  color: white;
  font-size: 3rem;
  font-family: 'Effra', sans-serif;
  font-weight: bold;
  font-style: italic;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .comingSoon {
    background-position: 30% 50%;
  }
  .comingSoon main {
    align-items: center;
  }
  .comingSoon .monogram {
    width: 35rem;
  }
  .comingSoon .content {
    right: auto;
    align-self: center;
  }
  .comingSoon .title {
    font-size: 5rem;
    margin: 0;
  }
  .comingSoon .logo {
    width: 25rem;
  }
  .comingSoon .instagramLogo {
    width: auto;
    height: 12rem;
    margin-bottom: 0;
  }
  .comingSoon .instagramContainer {
    margin-top: 5rem;
    gap: 1rem;
  }

  .comingSoon .instagramContainer p {
    font-size: 2rem;
  }

  .comingSoon .instagramContainer h3 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .comingSoon .content {
    right: auto;
    align-self: center;
  }
}

@media screen and (min-height: 600px){
  .comingSoon .content {
    top: 0;
  }
}
